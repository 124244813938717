.App {
  text-align: center;
  /* background-image: url('../../../public/images/from-man2mist-version2.png'); */

  /* background-position: initial; */

  /* learn how to make flex grow/shrink work
  for resizing screen and keeping background full screen
  also, this may not be flex grow/shrink */
  height: 100%;
  width: 100%;

  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  align-items: center;
}


.lightrope { /** CHRISTMAS LIGHTS!!! */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
}

.lightrope li {
  position: relative;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  background: rgba(0, 247, 165, 1);
  box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 1);
  animation-name: flash-1;
  animation-duration: 2s;
}

.lightrope li:nth-child(2n+1) {
  background: rgba(0, 255, 255, 1);
  box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.5);
  animation-name: flash-2;
  animation-duration: 0.4s;
}

.lightrope li:nth-child(4n+2) {
  background: rgba(247, 0, 148, 1);
  box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 1);
  animation-name: flash-3;
  animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  animation-duration: 1.8s;
}

.lightrope li:nth-child(3n+1) {
  animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.33333px;
  border-radius: 3px;
  top: -4.66667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  position: absolute;
  top: -14px;
  left: 9px;
  width: 52px;
  height: 18.66667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@keyframes flash-1 {
  0%, 100% {
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 1);
  }
  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-2 {
  0%, 100% {
    background: rgba(0, 255, 255, 1);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 1);
  }
  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-3 {
  0%, 100% {
    background: rgba(247, 0, 148, 1);
    box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 1);
  }
  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.66667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}




@media screen and (max-width: 430px) {
  .App {

    /* border: 3px solid red;
    border-radius: 40px; */

    background-image: url('../../images/backgrounds/mbl_background-360x640.png');
    /* background-image: url('../../images/backgrounds/dsktp_background-1280x720.png'); */
    background-repeat: no-repeat;
    /* background-size: 100%; */
    background-attachment: fixed;  /** keep background in place while scrolling */
    -webkit-background-attachment: fixed;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;

    overflow-x: hidden;
  }

  .home-wrapper {
    height: 55vh;
  }

  .home-wrapper > .yt-embed-wrapper > .video-responsive > iframe {
    width: 370px;
    height: 250px;
  }



  .swiper-boi {
    width: 80%;
  }


  .navbar-wrapper {
    width: 100%;
  }
  .my-nav-link {
    font-size: 14px;
    text-align: center;
  }



  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    /* position: absolute; */
  }


  .social-media-icoon {
    height: 40px;
    width: 40px;
  }





  .store-item-wrapper {
    width: 400px;
    padding: 0px;
  }




  .band-photo-description {
    width: auto;
  }


  .shows-page-header {
    margin: 30px 0px;
  }
  .show-dates-ul {
    margin: 5px;
  }
  .show-date-li {
    flex-direction: column;
    margin: 5px;
    margin-bottom: 20px;
  }

  .show-flyer {
    margin: 5px;
  }
  .show-description {
    margin: 5px;
  }
}


@media screen and (min-width: 431px) {
  .App {
    background-image: url('../../images/backgrounds/dsktp_background-1280x720.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}



/*
#root {
  height: 100vh;
  width: 100vw;

  height: 100%;
  width: 100%;
} */