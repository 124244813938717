/* Offcanvas styles */
.offcanvas {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: white;
    z-index: 1050;
    overflow-y: auto;
    padding: 1rem;
}

.offcanvas.show {
    display: block;
}

.offcanvas-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
}

.offcanvas-body {
    padding-top: 1rem;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.cart-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Cart item styles */
.cart-item {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cart-item img {
    width: 125px;
    height: 75px;
    object-fit: cover;
}

.cart-item h6 {
    margin: 0;
    font-size: 1rem;
}

.cart-item .remove-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 5px;
}

.cart-item .remove-btn:hover {
    background-color: darkred;
}
