.my-navbar-wrapper {
    /* border: 1px solid red;
    border-radius: 20px; */

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    flex-direction: column;
    /* width: 100%; */

    /* position: sticky;
    top: 0px; <-- use this to make the navbar
    stick to the top of the page when scrolling */
}

.navigation-links {
}

.my-nav-link {
    text-decoration: none;
    color: white;
    padding: 5px;
    font-size: 30px;
    font-weight: bold;
    min-width: 0;
    flex-shrink: 1;
    transition: font-size 0.3s ease;
}

.my-nav-link:hover {
    color: rgb(162, 68, 68);
    .cart-svg {
        fill: rgb(162, 68, 68);
    }
}

a:visited {
    text-decoration: none;
}

.cart-notification {
    display: flex;
    border: 1px solid red;
    border-radius: 40px;
    justify-content: center;
    align-items: center;
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: medium;
    background-color: orangered;
    color: white;
    transform: translate(10%, 10%);
}