.contact-wrapper {
    /* border: 1px solid red;
    border-radius: 20px; */

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* border-bottom: 1px solid black; */

    color: white;
    height: 70vh;
    font-size: 30px;
    font-weight: bold;

    padding-bottom: 110px; /* add room for audio player */
}

.contact-span {
    margin: 10px;
    padding: 5px;
}

.contact-span:hover {
    cursor: pointer;
    color: rgb(162, 68, 68);
}