.footer-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #336; /* Change this to your desired background color
  color: white; /* Change this to your desired text color */
  text-align: center;
  padding: 0px 0; /* Adjust the padding as needed */
  z-index: 1000; /* Ensures it appears on top of other content */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5); /* Optional: adds a shadow for better visibility */
  transition: height 0.3s ease; /* smooth transition */
  height: 120px;
}

.footer-wrapper.minimized {
  height: 25px;
  padding: 0;
  overflow: hidden;
}

.footer-toggle {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.footer-toggle:hover {
  color:  rgb(162, 68, 68);
}

.song-select {
  background-color: #333;
  text-align: center;
  color: #1ED760;
  font-size: 15px;
  border-radius: 40px;
}

.rhap_container .rhap_time {
  color: #1ED760; /* Replace with your desired color */
}