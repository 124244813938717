.floating-cart-button {
    position: fixed;
    bottom: 90px;
    right: 50px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1001;
    filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.15));
    transition: transform 0.3s ease, filter 0.3s ease;
}

.floating-cart-button:hover {
    transform: translateY(-5px);
    filter: drop-shadow(0 12px 20px rgba(0, 0, 0, 0.2));
}

.cart-notification {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ff3e3e;
    color: white;
    border-radius: 50%;
    padding: 6px 8px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}