.header-wrapper {
    /* border: 1px solid red;
    border-radius: 20px; */

    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;

    background-image: url('../../../public/images/Maldevera_logo-BONE_TEXTURE.png');

    /* background-image: url('../../../public/images//maldevera_logo.webp'); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}