.merch-wrapper {
    /* border: 1px solid red;
    border-radius: 20px; */

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* border-bottom: 1px solid black; */

    color: white;
    height: 70vh;
    font-size: 30px;
    font-weight: bold;
}

.merch-ul {
    /* border: 1px solid red;
    border-radius: 40px; */

    display: flex;
    flex-wrap: wrap;

    padding: 0px;

    justify-content: center;

    padding-bottom: 110px; /* add room for audio player */
}



.store-item-wrapper {
    display: flex;
    flex-direction: column;
    width: 500px;
}




.swiper-boi {
    /* border: 1px solid red;
    border-radius: 40px; */

    width: 100%;
    /* height: 400px; */
}


.swiper-slide {
    /* border: 1px solid red;
    border-radius: 40px; */

    display: flex;
    /* background-color: rgba(128, 255, 212, 0.5); */
}

.swiper-slide:hover {
    cursor: pointer;
}



.merch-loading {
    height: 70vh;
}